<template>
  <v-container class="pa-0">
    <v-row class="content-area">
      <v-col
        class="btn-container"
        cols="12"
      >
        <input
          v-model="name"
          type="text"
          class="input-location-name mb-2"
          placeholder="새 위치 이름"
        >
        <v-select
          v-if="$store.state.use_docking"
          v-model="selectedType"
          class="select-input mb-2"
          :items="typeList"
          item-color="grey"
          placeholder="위치 속성"
          height="8vh"
          outlined
          dark
        />
        <template v-if="selectedType!=='LOCATION' && false">
          <v-select
            v-model="dockingType"
            class="select-input mb-2"
            :items="dockingList"
            item-color="grey"
            placeholder="자동 도킹 설정"
            height="8vh"
            outlined
            dark
          />
        </template>
        <template v-if="selectedType!=='LOCATION'&&dockingType==='지정 도킹'">
          <v-select
            v-model="dockingStationId"
            class="select-input"
            :items="dockingStationList"
            item-color="grey"
            placeholder="도킹 스테이션 선택"
            height="8vh"
            outlined
            dark
          />
        </template>
        <v-row class="btn-adding-row-container ma-0 pa-0">
          <v-col class="btn-adding-col-container ma-0 py-0">
            <v-btn
              class="btn-adding mt-8 mr-4"
              color="#7c7c7c"
              dark
              @click="$router.replace('/new-location')"
            >
              취소
            </v-btn>
            <template v-if="name.length&&selectedType">
              <v-btn
                class="btn-adding mt-8 ml-4"
                color="#00BFB4"
                dark
                @click="saveLocation"
              >
                완료
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                class="btn-adding mt-8 ml-4"
                color="#00BFB4"
                dark
                disabled
              >
                완료
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from '@/main.js';

export default {
  name: 'NewLocationInRobot',
  data() {
    return {
      name: '',
      selectedType: 'LOCATION',
      typeList: [
        'LOCATION',
      ],
      locations: null,
      dockingType: '지정 도킹',
      dockingList: [
        // '자동 도킹',
        '지정 도킹'
      ],
      dockingStationId: null,
      dockingStationList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
  },
  created() {
    EventBus.$emit('lockLocationPopup');
    EventBus.$on('updateLocations', this.initialize);
    if(this.$store.state.use_docking){
      this.typeList.push('DOCKING');
    }
    if(this.$store.state.use_lift|| this.$store.state.use_conveyor){
      this.typeList.push('LOAD');
      this.typeList.push('UNLOAD');
      this.typeList.push('WORKSTATION');
    }
    this.initialize();
  },
  beforeDestroy() {
    EventBus.$off('updateLocations');
    EventBus.$emit('unLockLocationPopup');
  },
  methods: {
    initialize() {
      return this.fetchLocation().catch(err => { throw Error(err) });
    },
    fetchLocation() {
      return this.$axios.get('/location')
        .then(res => {
          if (res.status === 200) {
            this.locations = res.data;
          }
        });
    },
    saveLocation() {
      if (this.selectedType !== 'LOCATION' && this.dockingType === null) {
        this.$toast('자동 도킹을 설정하세요');
        return;
      }
      if (this.selectedType !== 'LOCATION' && this.dockingType === '지정 도킹' && this.dockingStationId === null) {
        this.$toast('도킹 스테이션을 선택하세요');
        return;
      }
      let ableToSave = this.locations.every(location => {
        return location.location_name !== this.name
      });
      if (ableToSave) {
        const data = {
          locationName: this.name,
          locationType: this.selectedType,
          autodocking: "Y",
          docking_id: 0
        }
        if (this.selectedType !== 'LOCATION' && this.dockingType === '지정 도킹') {
          data.autodocking = "N";
          data.docking_id = this.dockingStationId;
        }
        return this.$axios.post('/location', data)
          .then(res => {
            if (res.status === 200) {
              EventBus.$emit('robotSaveLocation');
              this.$router.replace('/new-location');
            }
          })
          .catch(err => { throw Error(err); });
      } else {
        this.$toast('중복된 위치명입니다');
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.content-area {
  height: 100%;
}

.btn-container {
  margin: 50px 0 0;
  height: 80% ;
  text-align: center;
  overflow: auto;
}

.btn-add {
  height: 30% !important;
  width: 70%;
  border-radius: 10px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

.btn-adding {
  height: 100% !important;
  width: 34%;
  border-radius: 10px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

.input-location-name {
  width: 70%;
  height: 70px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-location-name:focus {
  outline: #9aa0ae solid;
}

.input-location-degree {
  width: 70%;
  height: 70px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-location-degree:focus {
  outline: #9aa0ae solid;
}

.select-input {
  width: 70%;
  left: 15%;
  background-color: black;
  border: 2px solid #545861;
}

@media (max-width: 1024px) {
  .input-location-name {
    width: 70%;
    height: 45px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 2rem;
    color: white;
  }

  .input-location-degree {
    width: 70%;
    height: 45px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 2rem;
    color: white;
  }

  .btn-adding {
    height: 100% !important;
    width: 34%;
    border-radius: 10px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem !important;
    line-height: 117px;
    text-align: center;
  }

}
</style>

<style>
.content-area > .btn-container > .v-input > .v-input__control > .v-input__slot {
  margin: 0;
  text-align: center;
  font-size: 2.5vw;
}

.content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > #input-10 {
  max-height: none !important;
}

.content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > .v-select__selection--comma {
  overflow: inherit;
}

.content-area > .btn-container > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}
</style>
